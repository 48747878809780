import store from '@/store'
import router from '@/router'
import axios from 'axios';

axios.interceptors.request.use(function (config) {
  if (store.getters.is_logged_in) {
    config.headers['Accept-Language'] = store.getters.get_lang ? store.getters.get_lang : "de"
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    store.commit('logout', '');
    router.push('/auth/sign_in');
  }
  return Promise.reject(error);
});

export default axios;
