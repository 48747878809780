import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios';
import VueFinalModal from 'vue-final-modal'
import Notifications from '@kyvg/vue3-notification'
import "echarts";
import ECharts from 'vue-echarts'
import {
  // Directives
  VTooltip,
  // Components
  Tooltip
} from 'floating-vue'
import 'floating-vue/dist/style.css'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueFinalModal());
app.use(Notifications);
app.directive('tooltip', VTooltip);
app.component('v-chart', ECharts);
app.component('VTooltip', Tooltip);
app.config.globalProperties.$http = axios;
app.mount('#app')
