import { createStore } from 'vuex'

export default createStore({
  state: {
    nodion_token: localStorage.getItem('nodion_token') || '',
    user: {},
    otp_email: null,
    otp_pass: null
  },
  mutations: {
    add_token (state, payload) {
      state.nodion_token = payload;
    },
    add_user (state, payload) {
      state.user = payload;
    },
    logout (state) {
      state.nodion_token = "";
      localStorage.setItem('nodion_token', "");
    },
    otp_email (state, payload) {
      state.otp_email = payload;
    },
    otp_pass (state, payload) {
      state.otp_pass = payload;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    get_token: state => {
      return "Bearer "+state.nodion_token
    },
    get_user: state => {
      return state.user
    },
    is_logged_in: state => !!state.nodion_token,
    get_otp_email: state => {
      return state.otp_email
    },
    get_otp_pass: state => {
      return state.otp_pass
    },
  }
})
