<template>
  <div>
    <notifications position="top center" />
    <div v-if="is_logged_in">

      <header class="app-header">
        <div class="container">
          <p v-if="user.locked == false && user.default_project_detail && user.default_project_detail.id" class="logo">
            <router-link to="/dashboard"><img src="./assets/img/logo.svg"></router-link>
          </p>
          <p v-else class="logo">
            <router-link to="/"><img src="./assets/img/logo.svg"></router-link>
          </p>
          <ul v-if="user.locked == false && user.default_project_detail && user.default_project_detail.id" class="nav-main">
            <li>
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
                <span>Services</span>
                <svg style="width:16px;margin-left:4px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </a>
              <ul>
                <li>
                  <router-link to="/applications">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                    </svg>
                    <span>Applications</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/databases">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                    </svg>
                    <span>Databases</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/buckets">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                    </svg>
                    <span>Storage</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/dns_zones">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                    </svg>
                    <span>DNS Zones</span>
                  </router-link>
                </li>
                <!--<li>
                  <router-link to="/cdn_zones">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                    </svg>
                    <span>CDN Zones</span>
                  </router-link>
                </li>-->
                <li>
                  <router-link to="/observers">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>
                    <span>Monitoring</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="nav-meta">
            <li v-if="user.locked == false && user.default_project_detail && user.default_project_detail.id">
              <router-link to="/integrations">
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="git-alt" class="svg-inline--fa fa-git-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M439.55 236.05L244 40.45a28.87 28.87 0 0 0-40.81 0l-40.66 40.63 51.52 51.52c27.06-9.14 52.68 16.77 43.39 43.68l49.66 49.66c34.23-11.8 61.18 31 35.47 56.69-26.49 26.49-70.21-2.87-56-37.34L240.22 199v121.85c25.3 12.54 22.26 41.85 9.08 55a34.34 34.34 0 0 1-48.55 0c-17.57-17.6-11.07-46.91 11.25-56v-123c-20.8-8.51-24.6-30.74-18.64-45L142.57 101 8.45 235.14a28.86 28.86 0 0 0 0 40.81l195.61 195.6a28.86 28.86 0 0 0 40.8 0l194.69-194.69a28.86 28.86 0 0 0 0-40.81z"></path>
                </svg>
                <span>Integrations</span>
              </router-link>
            </li>
            <li>
              <router-link to="/tickets">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                <span>Support</span>
              </router-link>
            </li>
            <li>
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>{{ user.first_name }} {{ user.last_name }}</span>
                <svg style="width:16px;margin-left:4px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </a>
              <ul>
                <li v-if="user.onboarded">
                  <router-link to="/user/account">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <span>Account</span>
                  </router-link>
                </li>
                <li v-else>
                  <router-link to="/start/profile">
                    <span>Onboarding</span>
                    <svg style="width: 18px;margin-right: 0;margin-left:5px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                    </svg>
                  </router-link>
                </li>
                <!--<li v-if="this.user.onboarded">
                  <router-link to="/user/billing">Billing</router-link>
                </li>-->
                <li>
                  <a @click="logout" style="cursor:pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="nav-meta">
            <li>
              <router-link to="/projects">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
                <span>Projects</span>
              </router-link>
            </li>
            <small v-if="current_route && current_route != 'projects' && user && user.default_project_detail && user.default_project_detail.project_name" v-tooltip="user.default_project_detail.project_name">{{ user.default_project_detail.project_name }}</small>
          </ul>
        </div>
      </header>

      <div class="app-body">

        <div v-if="user.onboarded && user.locked == false && user.default_project_detail && user.default_project_detail.has_payment_method == false" class="method-info">
          <p>
            <svg style="display: inline-block;vertical-align: middle;width:20px;margin-right:6px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
            </svg>
            <span style="display: inline-block;vertical-align: middle;">Please remember to add a payment method to this project to access all instance types and prevent service suspension after your credits run out.</span>
          </p>
        </div>

        <div class="container">
          <router-view/>
        </div>
      </div>

    </div>
    <div v-else class="auth">

      <p class="auth-logo"><img src="./assets/img/logo.svg"></p>
      <div class="auth-box">
        <router-view/>
      </div>

    </div>

    <div class="update-modal" v-if="update_available">
      <h3>Update available!</h3>
      <p>To be served the newest version please click on the button below. This will reload this site.</p>
      <p class="btn"><a @click="reload_site" class="button button-main button-100">Reload now ›</a></p>
    </div>

    <div class="fe-version">
      v{{ api_version.version }}
    </div>

  </div>
</template>

<script>
export default {
  name: 'app',
  computed : {
    is_logged_in : function(){ return this.$store.getters.is_logged_in }
  },
  data() {
    return {
      api_version: {},
      app_version: null,
      user: {},
      update_available: false,
      project: {},
      current_route: null
    }
  },
  methods: {
    get_api_version() {
      this.current_route = this.$route.name;
      this.app_version = import.meta.env.VITE_BUILD;
      this.$http.get(import.meta.env.VITE_BASE_URL)
      .then(response => {
        this.api_version = response.data;
        this.check_build();
      })
    },
    check_build() {
      if (parseInt(this.api_version.build) > parseInt(import.meta.env.VITE_BUILD)) {
        this.update_available = true;
      }
    },
    get_user() {
      if (this.$store.getters.is_logged_in) {
        this.$http.get(import.meta.env.VITE_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
          this.$store.commit('add_user', response.data.user);
          // Does not work, otherwise other accessible sites not possible
          /*if (!this.user.onboarded) {
            this.$router.push('/start/profile');
          }*/
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit('logout', '');
            this.$router.push('/');
          }
        })
        this.$http.get(import.meta.env.VITE_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
        })
      }
    },
    get_user_simple() {
      if (this.$store.getters.is_logged_in) {
        this.$http.get(import.meta.env.VITE_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
          if (this.user.locked) {
            this.$router.push('/user/locked');
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$store.commit('logout', '');
            this.$router.push('/');
          } else if (error.response.status === 400) {
            if (error.response.data.errors == "user not found.") {
              this.$store.commit('logout', '');
              this.$router.push('/');
            }
          }
        })
      }
    },
    logout() {
      this.$store.commit('logout', '');
      this.$router.push('/');
    },
    reload_site() {
      location.reload();
    }
  },
  mounted () {
    this.get_api_version();
    this.get_user();

    setInterval(function () {
      this.get_api_version();
    }.bind(this), 10000);
  },
  watch:{
    $route (){
      this.current_route = this.$route.name;
      this.get_user_simple();
    }
  }
}
</script>

<style lang="scss">
  @import 'assets/css/fonts.scss';
  @import 'assets/css/base.css';
  @import 'assets/css/skadi.scss';
  @import 'assets/css/style.scss';

  .method-info {
    width: 100%;
    background: #fbf1f2;
    color: #842029;
    padding: 14px 0;
    text-align: center;
    border-bottom: 1px solid #f9e3e5;

    p {
      margin: 0;
    }
  }

  .update-modal {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #E6E7EA;
    -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    max-width: 500px;

    h3 {
      margin: 0 0 10px 0;
      font-weight: 600;
    }

    p {
      margin: 0;
      line-height: 1.3;
    }

    p.btn {
      margin-top: 10px;
    }
  }

  .fe-version {
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 10px;
    opacity: 0.5;
    padding: 5px;
    background: #fff;
    border: 1px solid #f1f1f1;
  }
</style>
